import React from "react"
import scrollTo from "gatsby-plugin-smoothscroll"
import { css } from "@emotion/core"
import styled from "@emotion/styled"

const StyledLink = styled.button`
  font-size: 16px;
  color: var(--whiter);
  font-weight: bold;
  text-decoration: none;
  margin: 0;
  background-color: transparent;
  border: 0;
`

const Nav = () => (
  <nav
    css={css`
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      max-width: 370px;
      width: 100%;
      margin: 0;
    `}
  >
    <StyledLink onClick={() => scrollTo("#about-us")}>about us</StyledLink>
    <StyledLink onClick={() => scrollTo("#services")}>services</StyledLink>
    <StyledLink onClick={() => scrollTo("#clients")}>clients</StyledLink>
    <StyledLink onClick={() => scrollTo("#contact")}>contact us</StyledLink>
  </nav>
)

export default Nav
