import React from "react"
import { css } from "@emotion/core"
import Nav from "./nav"

const Footer = () => {
  return (
    <footer
      css={css`
        height: 148px;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: space-evenly;
        background-color: var(--black);
      `}
    >
      <Nav />
      <p
        css={css`
          color: var(--whiter);
          margin: 0;
        `}
      >
        © {new Date().getFullYear()} Bagus dev.
      </p>
    </footer>
  )
}

export default Footer
