import React, { useEffect } from "react"
/*import { useStaticQuery, graphql } from "gatsby"*/
import { Global, css } from "@emotion/core"
import Footer from "./footer"
import logo from "../images/BgRepeat.png"

export const xl = "1024px"
export const sm = "576px"
export const md = "768px"
export const lg = "992px"

const Layout = ({ children }) => {
  /*const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)*/

  function moveBg() {
    document.body.style = `background-position: center ${window.scrollY / 75}%`
  }

  useEffect(() => {
    document.addEventListener("scroll", moveBg)
  }, [])

  return (
    <>
      <Global
        styles={css`
          @import url("https://fonts.googleapis.com/css2?family=Rubik&family=Shanti&display=swap");

          :root {
            --brown: #352926;
            --orange: #c84630;
            --black: #1b1817;
            --white: #fbf0ef;
            --whiter: #f7f4f3;
          }

          * {
            box-sizing: border-box;
          }

          h1,
          h2,
          h3,
          h4,
          h5,
          h6 {
            font-family: "Shanti", sans-serif;
          }

          body {
            margin: 0;
            background-color: var(--whiter);
            background-image: url('${logo}');
            color: #222222;
            font-family: "Rubik", sans-serif;
            background-repeat: repeat;
            transition: background-position 0.05s linear;
          }

          a {
            color: currentColor;
          }

          .container {
            padding: 0 20px;
            margin: 0 auto;

            @media (min-width: ${sm}) {
              max-width: 540px;
            }

            @media (min-width: ${md}) {
              max-width: 720px;
            }

            @media (min-width: ${lg}) {
              max-width: 960px;
            }

            @media (min-width: ${xl}) {
              max-width: 1002px;
            }
          }
        `}
      />
      {/*<Header siteTitle={data.site.siteMetadata.title} />*/}
      <main
        css={css`
          overflow: hidden;
        `}
      >
        {children}
      </main>
      <Footer />
    </>
  )
}

export default Layout
